import moment from 'moment';
import React, { useEffect, useState } from 'react';

const GridView = ({
  displayWeekNumber,
  isMobile,
  paginatedDays,
  currentPage,
  daysPerPage,
  handleInputChange,
  handleBlur,
  calculateDayHours,
}) => {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    isMobile ? setShowMore(false) : setShowMore(false);
  }, [isMobile]);
  return (
    <div
      className={`grid grid-${displayWeekNumber === 4 ? '1' : '4'} gap-4 mt-2`}
    >
      {paginatedDays?.map((day, index) => {
        if (!day) return null;

        const absoluteIndex = currentPage * daysPerPage + index; // Apsolutni indeks
        return (
          <div
            key={absoluteIndex}
            className='flex flex-col bg-[#e6eff0] p-4 rounded shadow'
          >
            <h4 className='font-bold text-lg'>
              {day.dayOfWeek}, {moment(day.date).format('DD.MM.YYYY')}
            </h4>

            {/** Polja za unos podataka */}
            {renderInputRow('Dolazak', 'startShift', day, absoluteIndex)}
            {renderInputRow('Odlazak', 'endShift', day, absoluteIndex)}
            <div className='flex justify-between items-center mt-2'>
              <span className='font-semibold'>Ukupno:</span>
              <span className='text-gray-700 font-bold '>
                {calculateDayHours(day)}
              </span>
            </div>
            {showMore && (
              <>
                {' '}
                {renderInputRow(
                  'Godišnji odmor',
                  'vacation',
                  day,
                  absoluteIndex
                )}
                {renderInputRow('Bolovanje', 'sickLeave', day, absoluteIndex)}
                {renderInputRow(
                  'Plaćeni dopust',
                  'paidLeave',
                  day,
                  absoluteIndex
                )}
                {renderInputRow(
                  'Neplaćeni dopust',
                  'unpaidLeave',
                  day,
                  absoluteIndex
                )}
                {renderInputRow(
                  'Službeni put',
                  'bussinesTravelHours',
                  day,
                  absoluteIndex
                )}
                {renderInputRow(
                  'Prekovremeni rad',
                  'overTimeHours',
                  day,
                  absoluteIndex
                )}
                {renderInputRow(
                  'Državni praznik',
                  'nationalHoliday',
                  day,
                  absoluteIndex
                )}
              </>
            )}
            {/* "More" Button */}
            <button
              onClick={() => setShowMore(!showMore)}
              className='mt-4 text-[#4c617b] hover:underline'
            >
              {showMore ? 'Sakrij ostala polja' : 'Prikaži sva polja'}
            </button>
          </div>
        );
      })}
    </div>
  );

  /** Helper funkcija za renderovanje reda sa inputom */
  function renderInputRow(label, name, day, absoluteIndex) {
    return (
      <div className='flex justify-between items-center mt-2'>
        <span className='font-semibold'>{label}:</span>
        <input
          type='text'
          name={name}
          value={day[name] || ''}
          className='border w-[6rem] p-1 rounded'
          onChange={(e) => handleInputChange(e, absoluteIndex, name)}
          onBlur={(e) => handleBlur(e, day)}
        />
      </div>
    );
  }
};

export default GridView;
