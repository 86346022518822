export default function FormAction({
  handleSubmit,
  type = 'Button',
  action = 'submit',
  text,
}) {
  return (
    <>
      {type === 'Button' ? (
        <button
          type={action}
          className='group relative w-full flex justify-center py-3 px-4 border-transparent text-sm font-medium rounded-md text-white gradient-bg-button focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-5'
          onSubmit={handleSubmit}
        >
          {text}
        </button>
      ) : (
        <></>
      )}
    </>
  );
}
