import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { auth } from '../config/config';
import FormAction from '../components/FormAction';
/* import { sendPasswordResetEmail } from 'firebase/auth'; */

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { oobCode } = useParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    console.log(auth);

    const actionCodeSettings = {
      url: `https://satnica-77a28.firebaseapp.com/password-reset?mode=resetPassword&oobCode=${oobCode}&lang=bs`,
    };

    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);

      setSuccess('Link za resetovanje passworda je poslat na vaš email.');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className='flex min-h-screen justify-center mt-10'>
      <div className='flex flex-col items-center'>
        <h2
          className='mt-6 text-center text-3xl text-gray-900 font-sans font-bold'
          style={{ fontFamily: 'Roboto' }}
        >
          Resetovanje Lozinke
        </h2>
        {error && <p className='text-red-600 mb-2'>{'Greška na serveru'}</p>}
        {success && <p className='text-green-600 mb-2'>{success}</p>}
        <form
          onSubmit={handleResetPassword}
          className='flex flex-col mt-[40px] min-w-[300px]'
        >
          <input
            type='email'
            className='appearance-none relative block w-full px-3 py-3 border border-gray-600 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#1e90ff] focus:border-[#89abde] text-sm'
            placeholder='Unesite email adresu'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormAction text='Resetuj Lozinku' />
        </form>
        <div className='mt-4'>
          <Link to='/' className='text-[#4c617b] opacity .8'>
            Povratak na login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
