import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { auth } from '../config/config';
import {
  getMonthByEmployee,
  updateMonthByEmployee,
  getEmployee,
} from '../api/api';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import {
  getShiftType,
  groupDaysByWeek,
  parseAndAdjustTime,
} from '../helpers/helpers';
import _, { initial } from 'lodash';
import PrintTable from '../components/PrintTable';
import Loader from '../components/Loader';
import 'jspdf-autotable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import bs from 'date-fns/locale/bs'; // Import Croatian locale
import '../index.css';
import alertContext from '../contexts/alert/alertContext';
import GridView from '../components/GridView';
import TableView from '../components/TableView';

const WorkLogScreen = () => {
  const GlobalContext = useContext(globalContext);

  const { employee, dispatch, companyDetails } = GlobalContext;

  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const [loading, setLoading] = useState(true);

  const [loadingSmall, setLoadingSmall] = useState(false);

  const AuthContext = useContext(authContext);

  const currentUserUid = auth.currentUser.uid;

  const { user } = AuthContext;

  const { startDate } = employee;

  const { workingMonths } = employee;

  const param = useParams();

  const { employeeId } = param;

  const [isHasChanges, setIsHasChanges] = useState(false);

  const [days, setDays] = useState(workingMonths?.workingDays);

  const [prevDaysState, setPrevDaysState] = useState([]);

  const [loadingMap, setLoadingMap] = useState({});

  const [monthHours, setMonthHours] = useState(0);
  const [totalMonthHours, setTotalMonthHours] = useState(0);

  const [leaveTotals, setLeaveTotals] = useState({
    totalPreYearVacation: 0,
    totalVacation: 0,
    totalSickLeave: 0,
    totalPaidLeave: 0,
    totalUnpaidLeave: 0,
    totalNationalHoliday: 0,
  });

  const [printClick, setPrintClick] = useState(null);

  const [totalWorkingHoursByMonth, setTotalWorkingHoursByMonth] = useState();

  moment.locale('bs');

  const date = moment(startDate);

  const minMonthValue = date.format('YYYY-MM');

  const currentDate = moment().format('YYYY-MM');

  const maxDate = moment().add(2, 'month');
  const maxMonthValue = maxDate.format('YYYY-MM');

  const [selectedMonth, setSelectedMonth] = useState(currentDate);

  const formatedDate = moment(selectedMonth).format('YYYY-MM');

  const fetchEmployeeData = async () => {
    const newMonthData = copyDataToNewMonth();

    // Check if employee.endDate is not defined or is an empty string

    try {
      setLoading(true);
      if (employee && selectedMonth) {
        let result = await getMonthByEmployee(
          formatedDate,
          user.uid,
          employeeId,
          newMonthData,
          setAlert
        );

        setDays(result.workingMonths.workingDays);
        setPrevDaysState(result.workingMonths.workingDays);
        dispatch({ type: 'DISPLAY_EMPLOYEE', payload: result });

        setLoading(false);

        // Set the originalDays to the initial state when days are fetched
      }
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployeeData();

    //eslint-disable-next-line
  }, [selectedMonth, employeeId]);

  useEffect(() => {
    dispatch({
      type: 'DISPLAY_EMPLOYEE',
      payload: {
        ...employee,
        workingMonths: { month: selectedMonth, workingDays: days },
      },
    });
    //eslint-disable-next-line
  }, [selectedMonth, isHasChanges]);

  const calculateDayHours = (day) => {
    const start =
      day.startShift && day.startShift !== '-'
        ? day.startShift.split(',').map(parseFloat)
        : [NaN, NaN];

    const end =
      day.endShift && day.endShift !== '-'
        ? day.endShift.split(',').map(parseFloat)
        : [NaN, NaN];

    if (isNaN(start[0]) || isNaN(start[1]) || isNaN(end[0]) || isNaN(end[1])) {
      return '-';
    }

    const startMinutes = start[0] * 60 + start[1];
    const endMinutes = end[0] * 60 + end[1];

    const totalMinutes = endMinutes - startMinutes;

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    // Use toFixed(1) to round to 1 decimal place
    return parseFloat((hours + minutes / 60).toFixed(1));
  };

  const calculateTotalMonthsHours = () => {
    let totalHours = 0;
    for (const day of days) {
      const dayHours = calculateDayHours(day);
      if (dayHours > 0) {
        totalHours += dayHours;
      }
    }
    setTotalMonthHours(totalHours);
  };

  const handleMonthChange = (date) => {
    setSelectedMonth(date);
  };

  useEffect(() => {
    if (employee?.endDate) setSelectedMonth(employee.endDate);
  }, []);

  const handleInputChange = (event, absoluteIndex, fieldName) => {
    const { value } = event.target;

    console.log(value);

    // Check if the input value is a positive integer up to 8 for specific fields
    const isValidInput =
      ([
        'preYearVacation',
        'vacation',
        'sickLeave',
        'paidLeave',
        'unpaidLeave',
        'overTimeHours',
        'bussinesTravelHours',
        'nationalHoliday',
      ].includes(fieldName) &&
        /^(?:[1-8]|)$/.test(value)) ||
      fieldName === 'startShift' ||
      fieldName === 'endShift' ||
      value === '';

    if (isValidInput || value === '') {
      const updatedDays = [...days];
      updatedDays[absoluteIndex][fieldName] = value;

      // Reset values in other fields based on the entered field
      const resetFields = [
        'preYearVacation',
        'vacation',
        'sickLeave',
        'paidLeave',
        'unpaidLeave',
        'overTimeHours',
        'bussinesTravelHours',
        'nationalHoliday',
      ];

      resetFields.forEach((field) => {
        if (field !== fieldName) {
          updatedDays[absoluteIndex][field] = '';
        }
      });

      // Check if any of the vacation, preYearVacation, or sickLeave inputs are filled
      const day = updatedDays[absoluteIndex];

      if (
        day.vacation ||
        day.preYearVacation ||
        day.sickLeave ||
        day.nationalHoliday
      ) {
        day.startShift = '-';
        day.endShift = '-';
      }

      // Get the startShift and endShift values from the updated day
      const startShift = updatedDays[absoluteIndex].startShift;
      const endShift = updatedDays[absoluteIndex].endShift;

      // Call the getShiftType function to determine the shift type
      const shiftType = getShiftType(startShift, endShift);

      // Update the shift type in the day object
      updatedDays[absoluteIndex].type = shiftType;

      setDays(updatedDays); // Update the full days array

      calculateTotalMonthsHours(); // Recalculate the total month hours
    }
  };

  useEffect(() => {
    if (days) {
      calculateTotalMonthsHours();
    }
    //eslint-disable-next-line
  }, [days, selectedMonth]);

  useEffect(() => {
    days && calculateLeaveDays();
    //eslint-disable-next-line
  }, [days]);

  const calculateLeaveDays = () => {
    let totalPreYearVacation = 0;
    let totalVacation = 0;
    let totalSickLeave = 0;
    let totalPaidLeave = 0;
    let totalUnpaidLeave = 0;
    let totalOverTimeHours = 0;
    let totalBussinesTravelHours = 0;
    let totalNationalHoliday = 0;

    for (const day of days) {
      const preYearVacation = parseInt(day.preYearVacation) || 0;
      const vacation = parseInt(day.vacation) || 0;
      const sickLeave = parseInt(day.sickLeave) || 0;
      const paidLeave = parseInt(day.paidLeave) || 0;
      const unpaidLeave = parseInt(day.unpaidLeave) || 0;
      const overTimeHours = parseInt(day.overTimeHours) || 0;
      const bussinesTravelHours = parseInt(day.bussinesTravelHours) || 0;
      const nationalHoliday = parseInt(day.nationalHoliday) || 0;

      // If sickLeave is entered, count it as a full day (8 hours)
      if (sickLeave > 0) {
        totalSickLeave += sickLeave; // Assuming a full day for sick leave
      }

      totalPreYearVacation += preYearVacation;
      totalVacation += vacation;
      totalPaidLeave += paidLeave;
      totalUnpaidLeave += unpaidLeave;
      totalOverTimeHours += overTimeHours;
      totalBussinesTravelHours += bussinesTravelHours;
      totalNationalHoliday += nationalHoliday;
    }

    setLeaveTotals({
      totalPreYearVacation,
      totalVacation,
      totalSickLeave,
      totalPaidLeave,
      totalUnpaidLeave,
      totalOverTimeHours,
      totalBussinesTravelHours,
      totalNationalHoliday,
    });
  };

  const handleBlur = async (event, day) => {
    const currentValue = event.target.value;
    const { name } = event.target;
    const date = day.date;

    try {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [name]: date,
        isActive: true,
      }));

      // Apply the special logic for startShift and endShift
      let finalValue;

      // Apply the special logic for startShift and endShift
      if (name === 'startShift' || name === 'endShift') {
        if (currentValue !== '-') {
          finalValue = parseAndAdjustTime(currentValue);
        } else {
          finalValue = '-';
        }
      } else {
        finalValue = currentValue;
      }

      // Set the value in the local state
      setDays((prevDays) => {
        return prevDays.map((day) => {
          if (day.date === date) {
            return {
              ...day,
              [name]: finalValue,
            };
          }

          return day;
        });
      });

      // Construct the data object for Firestore update
    } catch (error) {
      console.error('Error handling blur:', error);
    }

    /*    setIsHasChanges(true); */

    setLoadingMap((prevLoadingMap) => ({
      ...prevLoadingMap,
      [name]: date,
      isActive: false,
    }));
  };

  const handlePrint = async () => {
    setPrintClick(true);
    setTimeout(() => setPrintClick(null), 300);
  };

  const updateMonthDataHandeler = async () => {
    /*  setPrevDays([...days]); */
    try {
      setLoadingSmall(true);
      if (user.uid && employeeId && selectedMonth) {
        // Check if there are changes in the working days

        const updatedMonthData = await updateMonthByEmployee(
          user.uid,
          employee.employeeId,
          formatedDate,
          days,
          setAlert
        );

        if (updatedMonthData) {
          // Handle success, update the state, or perform other actions
          console.log('Uspješno ažuriran mjesec:', selectedMonth);
        } else {
          setAlert(
            'Greška na serveru',
            'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
          );
          setLoadingSmall(false);
        }
        setLoadingSmall(false);
      }
    } catch (error) {
      console.error('Error updating month data:', error);
      setLoading(false);
    }
  };

  const { employeeId: id } = param;

  const navigate = useNavigate();

  const handleEdit = () => {
    const fetchEmployee = async (id) => {
      const employee = await getEmployee(currentUserUid, id);

      dispatch({ type: 'DISPLAY_EMPLOYEE', payload: employee });
      navigate('/user/edit/' + id);
    };

    fetchEmployee(id);
  };

  const copyDataToNewMonth = () => {
    // dobar kod koji kopira zadnju sedmicu u mjesecu

    if (days && days.length > 0) {
      const newMonthStart = moment(selectedMonth).startOf('month'); // Prvi dan novog mjeseca
      const newMonthEnd = newMonthStart.clone().endOf('month'); // Posljednji dan novog mjeseca

      // Pronađi zadnju punu sedmicu iz prethodnog mjeseca (ponedjeljak do nedjelje)
      let lastFullWeek = [];
      for (let i = days.length - 1; i >= 0; i--) {
        const day = days[i];
        const dayOfWeek = moment(day.date, 'YYYY-MM-DD').isoWeekday(); // 1 = Pon, 7 = Ned

        // Ako pronađemo nedjelju, uzimamo prethodnih 7 dana
        if (dayOfWeek === 7) {
          lastFullWeek = days.slice(i - 6, i + 1); // Zadnja puna sedmica
          break;
        }
      }

      if (lastFullWeek.length !== 7) {
        console.error('Nije pronađena puna zadnja sedmica.');
        return null;
      }

      // Poravnaj sedmicu prema prvom danu u novom mjesecu
      const newMonthFirstDayOfWeek = newMonthStart.isoWeekday(); // Dan u sedmici za prvi dan novog mjeseca (1 = Pon, 7 = Ned)
      const alignedWeek = [];

      for (let i = 0; i < 7; i++) {
        const index = (newMonthFirstDayOfWeek - 1 + i) % 7; // Poravnanje prema prvom danu
        alignedWeek.push(lastFullWeek[index]);
      }

      // Ponavljanje sedmice kroz novi mjesec
      const newMonthDays = [];
      let weekIndex = 0;

      for (
        let currentDate = newMonthStart.clone();
        currentDate.isBefore(newMonthEnd) || currentDate.isSame(newMonthEnd);
        currentDate.add(1, 'day')
      ) {
        const sourceDay = alignedWeek[weekIndex % 7]; // Rotacija sedmice

        newMonthDays.push({
          ...sourceDay,
          date: currentDate.format('YYYY-MM-DD'), // Novi datum
        });

        weekIndex++;
      }

      const newMonthData = {
        month: newMonthStart.format('YYYY-MM'),
        workingDays: newMonthDays,
      };

      return newMonthData;
    } else {
      console.log('Nema podataka za kopiranje.');
      return null;
    }
  };

  useEffect(() => {
    getWorkingHoursInMonth(selectedMonth);
  }, [selectedMonth]);

  const getWorkingHoursInMonth = (selectedMonth) => {
    const hoursPerWorkDay = 8; // Standard working hours per day
    let workingDaysCount = 0;

    // Create a moment object from the Date
    const momentDate = moment(selectedMonth);
    const year = momentDate.year(); // Get the year
    const month = momentDate.month(); // Get the month (0-indexed)

    // Set the first and last date of the specified month
    const firstDay = moment(`${year}-${month + 1}-01`); // First day of the month
    const lastDay = firstDay.clone().endOf('month'); // Last day of the month

    // Initialize the day variable
    let day = firstDay.clone(); // Start from the first day

    // Iterate through each day of the month
    while (day.isBefore(lastDay.clone().add(0, 'days'))) {
      // Ensure to include the last day
      // Check if the day is a business day (Monday to Friday)
      if (day.isoWeekday() < 6) {
        // 1 (Monday) to 5 (Friday) are working days
        workingDaysCount++;
      }
      day.add(1, 'days'); // Move to the next day
    }

    // Calculate the total working hours for the month
    const totalWorkingHours = workingDaysCount * hoursPerWorkDay;
    setTotalWorkingHoursByMonth(totalWorkingHours);
  };

  const [displayWeekNumber, setDisplayWeekNumber] = useState(1);
  const [daysPerPage, setDaysPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(0);
  const [isMobile, setIsMobile] = useState(false); // To track if it's mobile view

  // Group days by week
  const newDays = groupDaysByWeek(days);

  // Get days for the current page
  let paginatedDays = days?.slice(
    currentPage * daysPerPage,
    (currentPage + 1) * daysPerPage
  );

  // Adjust daysPerPage based on displayWeekNumber and screen size
  useEffect(() => {
    // Check if screen width is less than 768px for mobile view
    const handleResize = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
        setDaysPerPage(1); // Show only 1 day per page on mobile
        setDisplayWeekNumber(4);
        setViewMode('grid');
      } else {
        setIsMobile(false);
        // Adjust based on displayWeekNumber
        if (displayWeekNumber === 1) {
          setDaysPerPage(7); // One week
          setCurrentPage(0);
        } else if (displayWeekNumber === 2) {
          setDaysPerPage(16); // Two weeks
          setCurrentPage(0);
        } else if (displayWeekNumber === 3) {
          setDaysPerPage(31); // One month
          setCurrentPage(0);
        } else if (displayWeekNumber === 4) {
          setDaysPerPage(1); // One Day
          setCurrentPage(0);
        } else {
          setDaysPerPage(7 * displayWeekNumber); // Multi-week
        }
      }
    };

    // Initialize screen size check
    handleResize();

    // Listen for window resize events
    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [displayWeekNumber]);

  // Calculate total pages
  const totalPages = Math.ceil(days?.length / daysPerPage);

  // Handle page navigation
  const goToNextPage = () => {
    if (currentPage < totalPages - 1) setCurrentPage((prev) => prev + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 0) setCurrentPage((prev) => prev - 1);
  };

  // Reset to the first page whenever selectedMonth changes
  useEffect(() => {
    setCurrentPage(0);
  }, [selectedMonth]);

  const [viewMode, setViewMode] = useState('table'); // "table" ili "grid"

  return (
    <div className='mx-auto container custom-container'>
      <div className='pt-5 p-5 relative'>
        {loading && employee ? (
          <div className=''>
            <Loader />
          </div>
        ) : (
          <div>
            <div style={{ display: 'none' }}>
              <PrintTable
                days={days}
                leaveTotals={leaveTotals}
                monthHours={monthHours}
                totalMonthHours={totalMonthHours}
                handlePrint={handlePrint}
                clickPrint={printClick}
                selectedMonth={selectedMonth}
                calculateDayHours={calculateDayHours}
              />
            </div>

            {employee?.endDate && (
              <div className='mb-2 flex w-[300px] px-2 py-1 bg-black text-red-700'>
                <p className='font-semibold'>Radnik odjavljen s danom: </p>
                <span className='font-semibold ml-1'>
                  {moment(employee?.endDate).format('DD-MM-YYYY')}
                </span>
              </div>
            )}
            {isMobile ? (
              <div>
                <h2 className='text-2xl text-gray-200 text-center sm:hidden block my-3'>
                  {companyDetails?.companyName}
                </h2>
                <h3 className='text-gray-300 font-medium text-center text-1xl'>{` ${employee?.firstName} ${employee?.lastName}`}</h3>
                <div className='text-center flex items-center justify-center my-3'>
                  <div className='custom-datapicker relative'>
                    <DatePicker
                      selected={new Date(selectedMonth)}
                      minDate={new Date(minMonthValue)}
                      maxDate={
                        employee.endDate
                          ? new Date(employee?.endDate)
                          : new Date(maxMonthValue)
                      }
                      onChange={handleMonthChange} // Handle month change
                      dateFormat='MMMM yyyy' // Set the desired date format
                      locale={bs} // Set the Croatian locale
                      showMonthYearPicker // Show only month and year picker
                      className='ml-2 text-lg font-semibold text-gray-900 bg-[#e6eff0] rounded-md'
                    />
                    <i class='fas fa-calendar absolute right-3 text-gray-500 pointer-events-none top-[5.5px]'></i>
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex justify-between text-[#eee]'>
                <div className='flex items-center font-semibold '>
                  <Link to='/dashboard' className='text-[#eee]'>
                    <div className='flex items-center'>
                      <i className='fa-solid fa-2x fa-users-line mr-1 text-white'></i>
                      <h3 className='my-2 font-semibold p-2 hover:text-blue-200'>
                        Zaposlenici {'>'}
                      </h3>
                    </div>
                  </Link>

                  <h3>{` ${employee?.firstName} ${employee?.lastName}`}</h3>
                </div>
                <div className='text-center flex items-center '>
                  <div className='custom-datapicker relative'>
                    <DatePicker
                      selected={new Date(selectedMonth)}
                      minDate={new Date(minMonthValue)}
                      maxDate={
                        employee.endDate
                          ? new Date(employee?.endDate)
                          : new Date(maxMonthValue)
                      }
                      onChange={handleMonthChange} // Handle month change
                      dateFormat='MMMM yyyy' // Set the desired date format
                      locale={bs} // Set the Croatian locale
                      showMonthYearPicker // Show only month and year picker
                      className='ml-2 text-lg font-semibold text-gray-900 rounded-sm'
                    />
                    <i class='fas fa-calendar absolute right-3 text-gray-500 pointer-events-none top-[5.5px]'></i>
                  </div>
                </div>

                <div className='hidden md:flex items-center justify-end'>
                  <div className='flex items-center justify-end mr-3'>
                    <button
                      className={`bg-[#3b82f6] hover:bg-blue-600 text-[#eee] text-sm font-bold py-2 px-4 rounded-full mr-5  ${
                        loadingSmall && `opacity-30`
                      }
        }`}
                      onClick={updateMonthDataHandeler}
                      disabled={loadingSmall}
                    >
                      Ažuriraj Mjesec
                    </button>

                    <div onClick={handleEdit} className='edit-icon'>
                      <i className='fa fa-2x  fa-edit ml-2 mr-2 text-[#eee]'></i>
                      <span>Edit</span>
                    </div>

                    <div onClick={handlePrint} className='print-icon'>
                      <i className='fa fa-2x  fa-print ml-2 mr-2 text-[#eee]'></i>
                      <span>Print</span>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='absolute top-[-15px]'>
              {loadingMap['isActive'] === true ||
                (loadingSmall && (
                  <div className=''>
                    <Loader />
                  </div>
                ))}
            </div>
            <div className='flex justify-between mt-2'>
              {!isMobile ? (
                <>
                  <div>
                    <label htmlFor='weekSelector' className='text-[#eee]'>
                      Prikaz Tabele:{' '}
                    </label>
                    <select
                      id='weekSelector'
                      className='bg-blue-100 p-1 rounded-sm'
                      value={displayWeekNumber}
                      onChange={(e) =>
                        setDisplayWeekNumber(Number(e.target.value))
                      }
                    >
                      <option value={4}>Jedan dan</option>
                      <option value={1}>Jedna sedmica</option>
                      <option value={2}>Pola mjeseca</option>
                      <option value={3}>Cijeli mjesec</option>
                    </select>
                  </div>
                  {/* Select za odabir prikaza */}
                  <div className='mb-4'>
                    <label className='mr-2 text-[#eee] rounded-sm'>
                      Prikaz podataka:
                    </label>
                    <select
                      value={viewMode}
                      onChange={(e) => setViewMode(e.target.value)}
                      className='bg-blue-100 p-1'
                    >
                      <option value='table'>Tabela</option>
                      <option value='grid'>Kartice</option>
                    </select>
                  </div>
                </>
              ) : null}
            </div>

            <div className=''>
              {/* Uvjetni prikaz na osnovu viewMode */}
              {viewMode === 'table' ? (
                <TableView
                  paginatedDays={paginatedDays}
                  currentPage={currentPage}
                  daysPerPage={daysPerPage}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  calculateDayHours={calculateDayHours}
                />
              ) : (
                <GridView
                  isMobile={isMobile}
                  paginatedDays={paginatedDays}
                  displayWeekNumber={displayWeekNumber}
                  currentPage={currentPage}
                  daysPerPage={daysPerPage}
                  handleInputChange={handleInputChange}
                  handleBlur={handleBlur}
                  calculateDayHours={calculateDayHours}
                />
              )}

              <div
                className={`pagination-controls mt-4 flex ${
                  currentPage !== 0 ? 'justify-between' : 'justify-end'
                }`}
              >
                {currentPage !== 0 && (
                  <div
                    className='text-[#eee] font-semibold'
                    onClick={goToPreviousPage}
                  >
                    <i className='fa-solid fa-circle-left'></i>
                    <span
                      className='px-4 py-2 cursor-pointer'
                      disabled={currentPage === 0}
                    >
                      {displayWeekNumber === 4
                        ? 'Prethodni dan'
                        : ' Predthodna sedmica'}
                    </span>
                  </div>
                )}

                {currentPage < totalPages - 1 && (
                  <div
                    className='text-[#eee] font-semibold cursor-pointer'
                    onClick={goToNextPage}
                  >
                    <i className='fa-solid fa-circle-right'></i>
                    <span
                      className='px-4 py-2'
                      disabled={currentPage === totalPages - 1}
                    >
                      {displayWeekNumber === 4
                        ? 'Slijedeći dan'
                        : ' Slijedeća Sedmica'}
                    </span>
                  </div>
                )}
              </div>
              <div className='md:hidden  block mt-4'>
                <div className='flex items-center justify-center '>
                  <button
                    className={`bg-[#EED] hover:bg-blue-500 hover:text-gray-200  text-gray-800 text-sm font-bold py-2 px-4 rounded-full  ${
                      loadingSmall && `opacity-30`
                    }
        }`}
                    onClick={updateMonthDataHandeler}
                    disabled={loadingSmall}
                  >
                    Ažuriraj Mjesec
                  </button>

                  <div className='hidden'>
                    <div onClick={handleEdit} className='edit-icon'>
                      <i className='fa fa-1x  fa-edit ml-2 mr-2 text-[#eee]'></i>
                      <span>Edit</span>
                    </div>

                    <div onClick={handlePrint} className='print-icon hidden'>
                      <i className='fa fa-1x  fa-print ml-2 mr-2 text-[#eee]'></i>
                      <span>Print</span>
                    </div>
                  </div>
                </div>
              </div>
              {/**Tabelu sam iskljucio jer mislim da mi ne treba ovaj total u opste */}

              {window.innerWidth > 750 && viewMode === 'table' && (
                <table className='w-full mt-10 table-total'>
                  <thead className='bg-gray-300' style={{ display: 'none' }}>
                    <tr className='bg-800-gray text-800-gray'>
                      <th
                        className='px-4 py-3 font-semibold'
                        data-tooltip-id='datum-tooltip'
                        data-tooltip-content='Datum rada'
                      >
                        Datum
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='dolazak-tooltip'
                        data-tooltip-content='Vrijeme dolaska na posao'
                      >
                        Dolazak
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='odlazak-tooltip'
                        data-tooltip-content='Vrijeme odlaska s posla'
                      >
                        Odlazak
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='ukupno-tooltip'
                        data-tooltip-content='Ukupno radno vrijeme za taj dan'
                      >
                        Ukupno
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='go-tooltip'
                        data-tooltip-content='Godišnji odmor'
                      >
                        Go
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='bolovanje-tooltip'
                        data-tooltip-content='Odsutnost zbog bolovanja'
                      >
                        Bolovanje
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='pla-tooltip'
                        data-tooltip-content='Plaćeni dopust'
                      >
                        Pla
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='nep-tooltip'
                        data-tooltip-content='Neplaćeni dopust'
                      >
                        Nep
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='sluz-tooltip'
                        data-tooltip-content='Službeni put'
                      >
                        Služ
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='prek-tooltip'
                        data-tooltip-content='Prekovremeni rad'
                      >
                        Prek
                      </th>
                      <th
                        className='px-4 py-2 font-semibold'
                        data-tooltip-id='dp-tooltip'
                        data-tooltip-content='Državni praznik'
                      >
                        DP
                      </th>
                    </tr>
                  </thead>
                  <tfoot className='mt-5 table-'>
                    <tr className='bg-gray-600 text-white'>
                      <td className='border px-4 py-2'>Ukupno:</td>
                      <td className='border px-4 py-2'></td>
                      <td className='border px-4 py-2'></td>
                      <td className='border px-4 py-2'></td>
                      {/*   <td className='border px-4 py-2'>
                    {leaveTotals.totalPreYearVacation}
                  </td> */}
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalVacation}
                      </td>
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalSickLeave}
                      </td>
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalPaidLeave}
                      </td>
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalUnpaidLeave}
                      </td>
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalBussinesTravelHours}
                      </td>
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalOverTimeHours}
                      </td>
                      <td className='border px-4 py-2'>
                        {leaveTotals.totalNationalHoliday}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              )}
            </div>

            <div className='mt-8 month-total'>
              <div className='flex justify-between items-center text-[#eee]'>
                <p className='mt-2'>
                  Mjesečno radio/la sati:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {totalMonthHours +
                      leaveTotals.totalOverTimeHours -
                      leaveTotals.totalUnpaidLeave}{' '}
                  </span>
                </p>
                <p className='mt-2'>
                  Radni Sati u Mjesecu:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {totalWorkingHoursByMonth}
                  </span>
                </p>
              </div>

              {leaveTotals.totalSickLeave !== 0 && (
                <p>
                  Bolovanje:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalSickLeave}
                  </span>
                </p>
              )}
              {leaveTotals.totalVacation !== 0 && (
                <p>
                  Godišnji odmor:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals?.totalVacation}{' '}
                  </span>
                </p>
              )}
              {leaveTotals.totalPreYearVacation !== 0 && (
                <p>
                  Godišnji odmor iz prošle godine:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals?.totalPreYearVacation}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalPaidLeave !== 0 && (
                <p>
                  Plaćeno odsustvo:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalPaidLeave}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalUnpaidLeave !== 0 && (
                <p>
                  Neplaćeno odsustvo:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalUnpaidLeave}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalBussinesTravelHours !== 0 && (
                <p>
                  Službeno putovanje:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalBussinesTravelHours}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalOverTimeHours !== 0 && (
                <p>
                  Prekovremeni rad:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalOverTimeHours}
                    {'  '}
                  </span>
                </p>
              )}
              {leaveTotals.totalNationalHoliday !== 0 && (
                <p>
                  Državni praznik:{' '}
                  <span className='font-semibold text-[#eee]'>
                    {leaveTotals.totalNationalHoliday}
                    {'  '}
                  </span>
                </p>
              )}
              <p className='mt-2 text-[#eee]'>
                Ukupno:{' '}
                <span className='font-semibold text-[#eee]'>
                  {totalMonthHours -
                    leaveTotals.totalUnpaidLeave +
                    leaveTotals.totalSickLeave +
                    leaveTotals.totalVacation +
                    leaveTotals.totalPreYearVacation +
                    leaveTotals.totalOverTimeHours +
                    leaveTotals.totalNationalHoliday}
                  {'  '}
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkLogScreen;
