import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { createDaysInMonth, parseAndAdjustTime } from '../helpers/helpers';
import { useParams } from 'react-router-dom';

const WeekdaysTable = ({ inputStyle, month, setWorkingMonthsInYear }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const param = useParams();

  const { employeeId } = param;

  const today = moment();

  const daysOfWeek = ['Pon', 'Uto', 'Sri', 'Čet', 'Pet', 'Sub', 'Ned'];

  const [workingDaysInWeek, setWorkingDaysInWeek] = useState([
    {
      date: '',
      dayOfWeek: 'Ned',
      startShift: '-',
      endShift: '-',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Pon',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Uto',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Sri',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Čet',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Pet',
      startShift: '8,00',
      endShift: '16,00',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
    {
      date: '',
      dayOfWeek: 'Sub',
      startShift: '-',
      endShift: '-',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    },
  ]);

  const sortedWorkingDaysInWeek = workingDaysInWeek.slice().sort((a, b) => {
    return daysOfWeek.indexOf(a.dayOfWeek) - daysOfWeek.indexOf(b.dayOfWeek);
  });

  const [workingDaysInSecondWeek, setWorkingDaysInSecondWeek] = useState(
    workingDaysInWeek.map((day) => ({
      date: day.date,
      dayOfWeek: day.dayOfWeek,
      startShift: day.dayOfWeek === 'Sub' || day.dayOfWeek === 'Ned' ? '-' : '',
      endShift: day.dayOfWeek === 'Sub' || day.dayOfWeek === 'Ned' ? '-' : '',
      sickLeaves: '',
      preYearVacation: '',
      vacation: '',
    }))
  );

  const sortedWorkingDaysInSecondWeek = workingDaysInSecondWeek
    .slice()
    .sort((a, b) => {
      return daysOfWeek.indexOf(a.dayOfWeek) - daysOfWeek.indexOf(b.dayOfWeek);
    });

  useEffect(() => {}, [workingDaysInSecondWeek]);

  const getWorkingMonthsInYear = (
    startYear,
    workingDaysInWeek,
    workingDaysInSecondWeek,
    selectedOptions,
    startDate
  ) => {
    const monthsInYear = [];
    const startMonth = moment(startDate);
    const startMonthIndex = startMonth.month();
    const currentYear = moment().year();

    for (let year = startYear; year <= currentYear; year++) {
      const startMonthIndexForYear = year === startYear ? startMonthIndex : 0;
      const endMonthIndexForYear =
        year === currentYear ? today.month() + 1 : 11;

      for (let i = startMonthIndexForYear; i <= endMonthIndexForYear; i++) {
        const monthDate = moment().year(year).month(i).date(1);
        const monthName = `${i + 1}`.padStart(2, '0');

        let daysInMonth;

        if (i === startMonthIndex && year === startYear) {
          const startDay = startMonth.date();

          daysInMonth = createDaysInMonth(
            startMonth,
            workingDaysInWeek,
            workingDaysInSecondWeek,
            selectedOptions,
            startDay
          );
        } else {
          daysInMonth = createDaysInMonth(
            monthDate,
            workingDaysInWeek,
            workingDaysInSecondWeek,
            selectedOptions
          );
        }

        monthsInYear.push({
          month: `${year}-${monthName}`,
          workingDays: daysInMonth,
        });
      }
    }

    setWorkingMonthsInYear(monthsInYear);

    return monthsInYear;
  };

  useEffect(() => {
    if (month) {
      getWorkingMonthsInYear(
        month.getFullYear(),
        workingDaysInWeek,
        workingDaysInSecondWeek,
        selectedOptions,
        month
      );
    }
    //eslint-disable-next-line
  }, [month, workingDaysInWeek, workingDaysInSecondWeek, selectedOptions]);

  const handleChange = (event, dayOfWeek, setWorkingDaysHandler) => {
    const currentValue = event.target.value;
    setWorkingDaysHandler((prevState) => {
      return prevState.map((day) => {
        if (day.dayOfWeek === dayOfWeek) {
          return {
            ...day,
            [event.target.name]: currentValue,
          };
        }
        return day;
      });
    });
  };

  const handleBlur = (event, dayOfWeek, setWorkingDaysHandler) => {
    const currentValue = event.target.value;

    // Reuse the logic from parseAndAdjustTime

    const finalValue = parseAndAdjustTime(currentValue);

    setWorkingDaysHandler((prevState) => {
      return prevState.map((day) => {
        if (day.dayOfWeek === dayOfWeek) {
          return {
            ...day,
            [event.target.name]: finalValue,
          };
        }
        return day;
      });
    });
  };

  const handleOptionChange = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedOptions([value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  return (
    <div>
      {!employeeId && (
        <div class='checkbox-wrapper-24 mx-2 pt-3'>
          <input
            type='checkbox'
            id='check-24'
            name='check'
            value='dvosedmično radno vrijeme'
            checked={selectedOptions.includes('dvosedmično radno vrijeme')}
            onChange={handleOptionChange}
          />
          <label for='check-24'>
            <span></span> Dvosedmično radno vrijeme
          </label>
        </div>
      )}
      {/* Prva tabela za Prvu Sedmicu (Za Veliki Ekran) */}
      <div className='hidden md:block'>
        <table className='text-sm my-10'>
          <thead className='bg-[#eee]'>
            <tr style={{ borderRadius: '10px', background: '#eee' }}>
              <th>
                <h3
                  className='mb-0 font-bold w-[140px]'
                  style={{ background: '#eee' }}
                >
                  Prva sedmica
                </h3>
              </th>
              {daysOfWeek.map((day) => (
                <th key={day}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr className='mr-2'>
              <td>Dolazak</td>
              {sortedWorkingDaysInWeek.map((day, index) => (
                <td key={index}>
                  <input
                    disabled={employeeId ? true : false}
                    type='text'
                    name='startShift'
                    value={day.startShift}
                    className={`${inputStyle} w-[6rem]`}
                    onChange={(e) =>
                      handleChange(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                    onBlur={(e) =>
                      handleBlur(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                  />
                </td>
              ))}
            </tr>
            <tr>
              <td>Odlazak</td>
              {sortedWorkingDaysInWeek.map((day, index) => (
                <td key={index}>
                  <input
                    type='text'
                    name='endShift'
                    value={day.endShift}
                    className={`${inputStyle} w-[6rem]`}
                    onChange={(e) =>
                      handleChange(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                    onBlur={(e) =>
                      handleBlur(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      {/* Tabela za Prvu Sedmicu (Za Mobilni Ekran) */}

      <div className='md:hidden my-5 rounded-lg border border-gray-300 shadow-sm overflow-hidden'>
        <table className='w-full text-sm text-left border-collapse'>
          <thead className='bg-gray-200'>
            <tr>
              <th className='px-4 py-2 text-gray-700 font-medium text-center'>
                Dan
              </th>
              <th className='px-4 py-2 text-gray-700 font-medium text-center'>
                Dolazak
              </th>
              <th className='px-4 py-2 text-gray-700 font-medium text-center'>
                Odlazak
              </th>
            </tr>
          </thead>
          <tbody className='bg-white divide-y divide-gray-200'>
            {sortedWorkingDaysInWeek.map((day, index) => (
              <tr key={index}>
                <td className='px-4 py-3 text-center font-medium text-gray-800'>
                  {daysOfWeek[index]}
                </td>
                <td className='px-4 py-3'>
                  <input
                    disabled={employeeId ? true : false}
                    type='text'
                    name='startShift'
                    value={day.startShift}
                    className='w-full px-2 py-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                    onChange={(e) =>
                      handleChange(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                    onBlur={(e) =>
                      handleBlur(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                  />
                </td>
                <td className='px-4 py-3'>
                  <input
                    disabled={employeeId ? true : false}
                    type='text'
                    name='endShift'
                    value={day.endShift}
                    className='w-full px-2 py-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                    onChange={(e) =>
                      handleChange(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                    onBlur={(e) =>
                      handleBlur(e, day.dayOfWeek, setWorkingDaysInWeek)
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h4 className='mb-0 text-center bg-blue-200 '>Prva sedmica</h4>
      </div>

      {/* Tabela za Drugu Sedmicu (Za Veliki Ekran) */}
      {!employeeId && selectedOptions.includes('dvosedmično radno vrijeme') && (
        <div className='hidden md:block'>
          <table className='text-sm my-10'>
            <thead className='bg-[#ffe]'>
              <tr style={{ borderRadius: '10px', background: '#ffe' }}>
                <th>
                  <h3
                    className='mb-0 font-bold w-[140px]'
                    style={{ background: '#ffe' }}
                  >
                    Druga sedmica
                  </h3>
                </th>
                {daysOfWeek.map((day) => (
                  <th key={day}>{day}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Dolazak</td>
                {sortedWorkingDaysInSecondWeek.map((day, index) => (
                  <td key={index}>
                    <input
                      disabled={employeeId ? true : false}
                      type='text'
                      name='startShift'
                      value={day.startShift}
                      className={`${inputStyle} w-[6rem]`}
                      onChange={(e) =>
                        handleChange(
                          e,
                          day.dayOfWeek,
                          setWorkingDaysInSecondWeek
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(e, day.dayOfWeek, setWorkingDaysInSecondWeek)
                      }
                    />
                  </td>
                ))}
              </tr>
              <tr>
                <td>Odlazak</td>
                {sortedWorkingDaysInSecondWeek.map((day, index) => (
                  <td key={index}>
                    <input
                      type='text'
                      name='endShift'
                      value={day.endShift}
                      className={`${inputStyle} w-[6rem]`}
                      onChange={(e) =>
                        handleChange(
                          e,
                          day.dayOfWeek,
                          setWorkingDaysInSecondWeek
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(e, day.dayOfWeek, setWorkingDaysInSecondWeek)
                      }
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {/* Tabela za Drugu Sedmicu (Za Mobilni Ekran) */}
      {!employeeId && selectedOptions.includes('dvosedmično radno vrijeme') && (
        <div className='md:hidden my-5 rounded-lg border border-gray-300 shadow-sm overflow-hidden'>
          <table className='w-full text-sm text-left border-collapse'>
            <thead className='bg-gray-200'>
              <tr>
                <th className='px-4 py-2 text-gray-700 font-medium text-center'>
                  Dan
                </th>
                <th className='px-4 py-2 text-gray-700 font-medium text-center'>
                  Dolazak
                </th>
                <th className='px-4 py-2 text-gray-700 font-medium text-center'>
                  Odlazak
                </th>
              </tr>
            </thead>
            <tbody
              className='bg-white divide-y divide-gray-200'
              style={{ background: 'rgba(219, 223, 194, 0.2)' }}
            >
              {sortedWorkingDaysInSecondWeek.map((day, index) => (
                <tr key={index}>
                  <td className='px-4 py-3 text-center font-medium text-gray-800'>
                    {daysOfWeek[index]}
                  </td>
                  <td className='px-4 py-3'>
                    <input
                      disabled={employeeId ? true : false}
                      type='text'
                      name='startShift'
                      value={day.startShift}
                      className='w-full px-2 py-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                      onChange={(e) =>
                        handleChange(
                          e,
                          day.dayOfWeek,
                          setWorkingDaysInSecondWeek
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(e, day.dayOfWeek, setWorkingDaysInSecondWeek)
                      }
                    />
                  </td>
                  <td className='px-4 py-3'>
                    <input
                      disabled={employeeId ? true : false}
                      type='text'
                      name='endShift'
                      value={day.endShift}
                      className='w-full px-2 py-1 text-center border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                      onChange={(e) =>
                        handleChange(
                          e,
                          day.dayOfWeek,
                          setWorkingDaysInSecondWeek
                        )
                      }
                      onBlur={(e) =>
                        handleBlur(e, day.dayOfWeek, setWorkingDaysInSecondWeek)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <h4 className='mb-0 text-center bg-blue-200 '>Druga sedmica</h4>
        </div>
      )}
    </div>
  );
};

export default WeekdaysTable;
