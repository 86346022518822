import './index.css';
import './print.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import DashBoard from './pages/DashBoard';
import Header from './components/Header';
import PrivateRoutes from './auth/PrivateRoutes';
import CreateEditPage from './pages/CreateEditPage';
import WorkLogScreen from './pages/WorkLogScreen';
import Alerts from './components/Alerts';
import Contact from './pages/Contact';
import PasswordReset from './pages/PasswordReset';
import Footer from './components/Footer';
import { useLocation } from 'react-router-dom';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditions from './pages/TermsPage';

const App = () => {
  const location = useLocation();
  return (
    <>
      <div
        className={`wrapper min-h-[93.5vh] ${
          location.pathname !== '/' &&
          location.pathname !== '/password-reset' &&
          location.pathname !== '/register' &&
          location.pathname !== '/terms' &&
          location.pathname !== '/privacy'
            ? 'gradient-bg'
            : ''
        }`}
      >
        <Alerts />
        <Header />

        <Routes>
          <Route element={<LoginPage />} path='/' />
          <Route element={<SignupPage />} path='/register' />
          <Route element={<PasswordReset />} path='/password-reset' />
          <Route element={<PrivacyPolicyPage />} path='/privacy' />
          <Route element={<TermsAndConditions />} path='/terms' />

          <Route element={<PrivateRoutes />}>
            <Route element={<DashBoard />} path='/dashboard' exact />
            <Route element={<CreateEditPage />} path='/user' exact />
            <Route
              element={<CreateEditPage />}
              path='/user/edit/:employeeId'
              exact
            />
            <Route
              element={<WorkLogScreen />}
              path='/worklog/:employeeId/'
              exact
            />
            <Route element={<Contact />} path='/kontakt' exact />
          </Route>
        </Routes>
      </div>
      <Footer />
    </>
  );
};

export default App;
