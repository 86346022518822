import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import mobile from '../assets/mobile.png';
import email from '../assets/email.png';
import Loader from '../components/Loader';

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null); // For displaying success or error messages
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Obavezno polje'),
      email: Yup.string().email('Pogrešan email').required('Obavezno polje'),
      subject: Yup.string().required('Obavezno polje'),
      message: Yup.string().required('Obavezno polje'),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true); // Set loading to true

    try {
      const response = await axios.post(
        'https://satnica.net/email/submit-form',
        values
      );

      // Show success message
      setMessage({
        type: 'success',
        message: `Hvala, ${values.fullName}, kontaktirat ćemo vas uskoro!`,
      });
      response.status === 200 && formik.resetForm(); // Reset the form fields
    } catch (error) {
      // Show error message
      setMessage({
        type: 'error',
        message: 'Geška prilikom slanja podataka!',
      });
      console.error('Error sending form:', error);
    } finally {
      setLoading(false);

      // Clear the message after a timeout (5 seconds)
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    }
  };

  const errorStyle = {
    color: '#FF0000',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '5px',
  };

  return (
    <>
      <div className='custom container px-3 mx-auto contact'>
        <div className='text-center my-2'>
          <h3 className='text 1xl text-gray-200'>Kontakt</h3>
          <p className='mt-4 text-gray-200'>
            Za sve informacije vezano za aplikaciju Satnica <br /> stojimo vam
            na raspolaganju.
          </p>
        </div>
        <div className='contact-form-container'>
          <form
            onSubmit={formik.handleSubmit}
            className='contact-form mt-10 rounded-lg'
          >
            <h2 className='text-2xl font-normal text-center mb-5 font-sans text'>
              Kontaktirajte nas
            </h2>
            {loading && (
              <div className='relative top-[-20px]'>
                {' '}
                <Loader />
              </div>
            )}
            {message && (
              <div
                className={`mb-4 p-4 rounded-lg ${
                  message.type === 'success'
                    ? 'bg-green-100 text-green-700'
                    : 'bg-red-100 text-red-700'
                }`}
              >
                {message.message}
              </div>
            )}

            <div className='flex mb-4 mt-3'>
              <div className='w-1/2 mr-4'>
                <input
                  id='fullName'
                  type='text'
                  placeholder='Ime i Prezime'
                  className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
                  {...formik.getFieldProps('fullName')}
                />
                {formik.touched.fullName && formik.errors.fullName && (
                  <div className='text-red-600 text-sm mt-1'>
                    {formik.errors.fullName}
                  </div>
                )}
              </div>

              <div className='w-1/2'>
                <input
                  id='email'
                  type='email'
                  placeholder='Email'
                  className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='text-red-600 text-sm mt-1'>
                    {formik.errors.email}
                  </div>
                )}
              </div>
            </div>

            <div className='mb-4'>
              <input
                id='subject'
                placeholder='predmet'
                type='text'
                className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200'
                {...formik.getFieldProps('subject')}
              />
              {formik.touched.subject && formik.errors.subject && (
                <div className='text-red-600 text-sm mt-1'>
                  {formik.errors.subject}
                </div>
              )}
            </div>

            <div className='mb-4'>
              <textarea
                rows={5}
                placeholder='poruka'
                id='message'
                className='w-full px-4 py-2 border rounded-lg focus:border-blue-500 focus:outline-none transition duration-200 text-gray-700'
                {...formik.getFieldProps('message')}
              />
              {formik.touched.message && formik.errors.message && (
                <div className='text-red-600 text-sm mt-1'>
                  {formik.errors.message}
                </div>
              )}
            </div>

            <button
              type='submit'
              className={`w-full gradient-bg-button text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none transition duration-200 ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading} // Disable button while loading
            >
              {loading ? 'Slanje...' : 'Pošalji'}
            </button>
          </form>
          <div
            className='contact-details mt-5 sm:mt-10'
            stlye={{ marginTop: window.innerWidth < 700 ? '20px' : '2.5rem' }}
          >
            <div className='flex gap-2 items-center'>
              <img src={mobile} alt='' />
              <div className='contact-info'>
                <p>+387 61 056 710</p>
                <p>+387 62 428 620</p>
              </div>
            </div>
            <div className='flex gap-2 items-center mt-5'>
              <img src={email} alt='' />
              <div className='contact-info'>
                <p>info@satnica.net</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
