import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg font-roboto mt-10'>
      <h1 className='text-3xl font-semibold text-gray-800 text-center mb-6'>
        Uvjeti korištenja - Satnica
      </h1>
      <p className='text-lg text-gray-700 mb-6'>
        Dobrodošli u aplikaciju Satnica! Ova stranica sadrži uvjete korištenja
        naše aplikacije i usluga.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        1. Usluga
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Aplikacija Satnica omogućuje zaposlenicima praćenje radnog vremena,
        uključujući dolazak i odlazak s posla, praćenje godišnjih odmora, te
        raznih preplata. Korisnici mogu odabrati između 5 različitih paketa
        usluga.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        2. Probni period
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Aplikacija nudi besplatan probni period od 14 dana. Za vrijeme ovog
        perioda, korisnici mogu koristiti sve funkcionalnosti aplikacije bez
        ikakvih troškova. Nakon isteka probnog perioda, korisnici će morati
        odabrati jedan od paketa i izvršiti uplatu ako žele nastaviti koristiti
        aplikaciju.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        3. Čuvanje podataka
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Podaci korisnika se čuvaju na različite vremenske periode, ovisno o
        njihovoj aktivnosti:
      </p>
      <ul className='list-disc pl-6 text-lg text-gray-700 mb-6'>
        <li>
          Ako korisnik koristi probni period i odustane, svi podaci će biti
          izbrisani nakon 2 mjeseca.
        </li>
        <li>
          Ako korisnik plati godišnju pretplatu i kasnije odustane, podaci će se
          čuvati godinu dana nakon prestanka korištenja usluge.
        </li>
        <li>
          Podaci korisnika koji redovito koriste uslugu podatci će biti
          pohranjeni do 3 godine.
        </li>
      </ul>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        5. Održavanje i izmjene uvjeta
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Ovi uvjeti korištenja mogu biti izmijenjeni u budućnosti. O svim
        promjenama bit ćete obaviješteni putem e-maila ili obavijesti unutar
        aplikacije.
      </p>

      <p className='text-lg text-blue-600 font-semibold text-center mt-6'>
        Za sva dodatna pitanja, slobodno nas kontaktirajte putem emaila ili
        telefonske linije.
      </p>
    </div>
  );
};

export default TermsAndConditions;
