import React from 'react';
import Signup from '../components/Signup';
import { Helmet } from 'react-helmet';

const SignupPage = () => {
  return (
    <div className='flex flex-col md:py-12 py-8 px-4 sm:px-6 lg:px-8 md:w-[30rem] m-auto'>
      <Helmet>
        <title>
          Registracija – Satnica Aplikacija za praćenje radnog vremena
        </title>
        <meta
          name='description'
          content='Prijavite se na Satnica aplikaciju za praćenje radnog vremena.'
        />

        <meta property='og:title' content='Registracija – Satnica Aplikacija' />
        <meta
          property='og:description'
          content='Registrujte se na Satnica aplikaciju i započnite 14 dana besplatnog korištenja.'
        />
        <meta property='og:image' content='/register-picture.png' />
        <meta property='og:image:width' content='1155' />
        <meta property='og:image:height' content='920' />
        <meta property='og:url' content='https://app.satnica.net/register' />
        <meta property='og:type' content='website' />
      </Helmet>

      <div className='mb-10'>
        <h2
          className='mt-6 text-center text-3xl text-gray-900 font-sans font-bold'
          style={{ fontFamily: 'Roboto' }}
        >
          Registriraj se
        </h2>
        <h3 className='mt-3 text-center text-[#4c617b] font-medium'>
          Besplatno korištenje 14 dana
        </h3>
      </div>
      <Signup />
    </div>
  );
};

export default SignupPage;
