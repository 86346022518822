import React from 'react';
import { Link } from 'react-router-dom';
import { LogoutButton } from './LogoutButton';
import NotificationIcon from '../icons/NotificationIcon';

const Navbar = () => {
  return (
    <ul className='flex items-center pt-2'>
      {/*   <li className='mr-2'>
        <Link to='/upute'>Upute</Link>
      </li>
 */}
      <li className='mr-2 text-white bg-[#4c617b] hover:opacity-[.9] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm  text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
        <Link className='px-5 py-1.5 block w-100' to='/kontakt'>
          Kontakt
        </Link>
      </li>
      <li className='mr-2 text-white bg-[#4c617b] hover:opacity-[.9] focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm  text-center me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
        <LogoutButton />
      </li>
      {/*  <li className='flex items-start'>
        {' '}
        <NotificationIcon />
      </li> */}
    </ul>
  );
};

export default Navbar;
