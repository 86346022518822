const fixedInputClass =
  'appearance-none relative block w-full px-3 py-2 border border-gray-600 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-[#1e90ff] focus:border-[#89abde] text-sm';

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  customClass,
}) {
  return (
    <div className='mt-4 mb-1'>
      <label htmlFor={labelFor}>{labelText}*</label>
      <input
        style={{ marginTop: '5px' }}
        onChange={handleChange}
        value={value}
        id={id}
        name={name}
        type={type}
        required={isRequired}
        className={fixedInputClass + customClass}
        placeholder={placeholder}
      />
    </div>
  );
}
