import React, { useContext } from 'react';
import authContext from '../contexts/auth/authContext';
import Logo from './Logo';
import Navbar from './Navbar';
import globalContext from '../contexts/satnica/globalContext';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);

  const { companyDetails } = GlobalContext;

  const { companyName } = companyDetails;

  const { user } = AuthContext;

  const location = useLocation();
  return (
    <div className='border-b divide-slate-200 bg-[#fff]'>
      <div className='custom-container mx-auto'>
        <header className='flex justify-between items-center p-4'>
          <Logo />
          <h2 style={{ fontSize: '25px' }} className='hidden sm:block'>
            {companyName}
          </h2>
          {user ? (
            <Navbar />
          ) : (
            <ul className='flex items-center'>
              {location.pathname === '/' ? (
                <li className='button-nav'>
                  <Link className='px-5 py-1.5 block w-100 ' to='/register'>
                    Registriraj se
                  </Link>
                </li>
              ) : (
                <li className='button-nav'>
                  <Link className='px-5 py-1.5 block w-100  ' to='/'>
                    Prijavi se
                  </Link>
                </li>
              )}
            </ul>
          )}
        </header>
      </div>
    </div>
  );
};

export default Header;
