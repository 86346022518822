import React from 'react';

const NotificationIcon = () => {
  return (
    <svg
      width='30'
      height='28'
      viewBox='0 0 30 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.67672 8.05L5.06013 5.57667L6.79224 3.93167L9.39655 6.41667L7.67672 8.05ZM16.2759 1.16667V4.66667H13.819V1.16667H16.2759ZM25.0347 5.57667L22.4181 8.05L20.6983 6.41667L23.3026 3.93167L25.0347 5.57667ZM5.83405 12.25V14.5833H2.14871V12.25H5.83405ZM24.2608 12.25H27.9461V14.5833H24.2608V12.25ZM7.67672 23.3333H22.4181C23.0697 23.3333 23.6946 23.5792 24.1554 24.0168C24.6161 24.4543 24.875 25.0478 24.875 25.6667H5.21983C5.21983 25.0478 5.47868 24.4543 5.93944 24.0168C6.40019 23.5792 7.02511 23.3333 7.67672 23.3333ZM15.0474 5.83333C17.0022 5.83333 18.877 6.57083 20.2593 7.88359C21.6416 9.19634 22.4181 10.9768 22.4181 12.8333V22.1667H7.67672V12.8333C7.67672 10.9768 8.45328 9.19634 9.83555 7.88359C11.2178 6.57083 13.0926 5.83333 15.0474 5.83333Z'
        fill='#FF7474'
      />
    </svg>
  );
};

export default NotificationIcon;
