import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className='max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg font-roboto mt-10'>
      <h1 className='text-3xl font-semibold text-gray-800 text-center mb-6'>
        Politika privatnosti - Satnica
      </h1>
      <p className='text-lg text-gray-700 mb-6'>
        Vaša privatnost nam je vrlo važna. Ovdje objašnjavamo kako prikupljamo,
        koristimo i štitimo vaše osobne podatke.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        1. Prikupljanje podataka
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Prikupljamo podatke kao što su vaše ime, email adresa, broj telefona,
        kao i podaci vezani uz vašu upotrebu aplikacije, uključujući radno
        vrijeme i godišnji odmor.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        2. Korištenje podataka
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Vaši podaci koriste se kako bismo omogućili funkcionalnost aplikacije,
        poput praćenja radnog vremena i generiranja izvještaja. Podaci također
        mogu biti korišteni za pružanje korisničke podrške i poboljšanje usluge.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        3. Sigurnost podataka
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Poduzimamo sve potrebne mjere kako bismo zaštitili vaše podatke od
        neovlaštenog pristupa, gubitka ili zloupotrebe. Podaci se pohranjuju na
        siguran način i pristupaju im samo ovlaštene osobe.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        4. Dijeljenje podataka
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Vaši podaci neće biti dijeljeni s trećim stranama bez vašeg izričitog
        pristanka, osim ako to nije potrebno za ispunjenje zakonskih obveza ili
        za pružanje usluga koje ste zatražili.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        5. Prava korisnika
      </h2>
      <p className='text-lg text-gray-700 mb-4'>
        Imate pravo na pristup, ispravak, brisanje ili ograničavanje obrade
        svojih osobnih podataka. Ako želite ostvariti bilo koje od ovih prava,
        kontaktirajte nas putem emaila.
      </p>

      <h2 className='text-2xl font-semibold text-gray-800 mt-8 mb-4'>
        6. Čuvanje podataka
      </h2>
      <p className='text-lg text-gray-700 mb-6'>
        Podaci se čuvaju u skladu s našom politikom zadržavanja podataka, ovisno
        o vašoj aktivnosti u aplikaciji.
      </p>

      <p className='text-lg text-blue-600 font-semibold text-center mt-6'>
        Za sva pitanja u vezi s privatnošću, slobodno nas kontaktirajte na
        info@satnica.net.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
