import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { registerUser } from '../api/api';
import { signupFields } from '../constants/formFields';
import alertContext from '../contexts/alert/alertContext';
import authContext from '../contexts/auth/authContext';
import FormAction from './FormAction';
import Input from './Input';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Loader from '../components/Loader';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA component
import { recaptchaKey } from '../config/config';
import axios from 'axios';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';

const fields = signupFields;

export default function Signup() {
  const AlertContext = useContext(alertContext);
  const { setAlert } = AlertContext;

  const AuthContext = useContext(authContext);
  const { dispatch, smallLoading } = AuthContext;

  const [initialPricingPlan, setInitialPricingPlan] = useState(6); // Default plan

  const location = useLocation();
  const navigate = useNavigate();

  // Extract query parameter "paket" from the URL
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paket = urlParams.get('paket');
    if (paket) {
      setInitialPricingPlan(parseInt(paket)); // Assuming "paket" is a number
    }
  }, [location]);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Unesite validan format e-maila')
      .required('Email je obavezan'),
    password: Yup.string()
      .min(6, 'Lozinka mora imati najmanje 6 karaktera')
      .required('Lozinka je obavezna'),
    phone: Yup.number()
      .min(6, 'Broj Telefona')
      .required('Broj telefona je obavezan'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Lozinke se moraju poklapati')
      .required('Potvrda lozinke je obavezna'),
    companyName: Yup.string().required('Naziv firme je obavezan'),
    pricingPlan: Yup.number().required('Odaberite plan cijena'),
    terms: Yup.boolean().oneOf(
      [true],
      'Morate prihvatiti uvjete korištenja i pravila privatnosti'
    ), // Ensure terms is true;
    recaptcha: Yup.string().required('Molimo vas da potvrditе da niste robot.'), // Add reCAPTCHA validation
  });

  // State to hold reCAPTCHA response token
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleRecaptchaChange = (value, setFieldValue) => {
    setRecaptchaToken(value);
    setFieldValue('recaptcha', value);
  };

  useEffect(() => {
    // Set trial start and end dates
    const trialStart = Timestamp.now();
    const trialEnd = Timestamp.fromDate(
      new Date(Date.now() + 14 * 24 * 60 * 60 * 1000) // 14 days from now
    );

    const newDate = moment(trialEnd.toDate()).format('DD.MM.YYYY');

    console.log(trialEnd, newDate);
  }, []);

  const sendEmailTemplate = async (email, companyName, trialEnd) => {
    try {
      const response = await axios.post(
        'https://satnica.net/email/send-welcome-email',
        { email, companyName, trialEnd }
      );
    } catch (error) {
      console.error('Error sending form:', error);
    }
  };

  return (
    <Formik
      key={initialPricingPlan}
      initialValues={{
        email: '',
        password: '',
        phone: '',
        confirmPassword: '',
        companyName: '',
        pricingPlan: initialPricingPlan, // Use the pre-selected plan
        terms: false,
        recaptcha: '', // Add recaptcha field to initial values
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        if (!recaptchaToken) {
          setAlert('reCAPTCHA verification is required', 'error');
          return;
        }

        console.log(values);

        dispatch({ type: 'SET_SMALL_LOADING', payload: true });
        const { email, password, phone, companyName, pricingPlan, terms } =
          values;

        // Pass the reCAPTCHA token to the registerUser function
        registerUser(
          email,
          password,
          phone,
          companyName,
          pricingPlan,
          navigate,
          setAlert,
          dispatch,
          terms,
          sendEmailTemplate
        );
      }}
    >
      {({ handleChange, values, errors, touched, setFieldValue }) => (
        <Form className='space-y-6'>
          {smallLoading && <Loader />}
          <div className='pt-1'>
            <div className='my-3'>
              <label
                htmlFor='pricingPlan'
                className='block text-sm font-medium text-gray-700 mb-2'
              >
                Odaberite plan
              </label>
              <select
                name='pricingPlan'
                id='pricingPlan'
                value={values.pricingPlan}
                onChange={handleChange}
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                required
              >
                <option value={6}>6 radnika(160km) god.</option>
                <option value={12}>12 radnika(250km) god.</option>
                <option value={25}>25 radnika(350km) god.</option>
                <option value={40}>40 radnika(500km) god.</option>
              </select>
              {touched.pricingPlan && errors.pricingPlan && (
                <span className='text-red-700 text-sm p-0 m-0'>
                  {errors.pricingPlan}
                </span>
              )}
            </div>
            {fields.map((field) => (
              <div key={field.id} className='my-3 remove-label'>
                <Input
                  handleChange={handleChange}
                  value={values[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.id}
                  type={field.type}
                  isRequired={field.isRequired}
                  placeholder={field.placeholder}
                />
                {touched[field.id] && errors[field.id] && (
                  <span className='text-red-700 text-sm p-0 m-0'>
                    {errors[field.id]}
                  </span>
                )}
              </div>
            ))}

            {/* Add reCAPTCHA */}
            {/* Add Terms and Conditions Checkbox */}
            <div className='my-3 flex items-start'>
              <div class='checkbox-wrapper-24 pt-1'>
                <input
                  type='checkbox'
                  id='check-24'
                  name='terms'
                  checked={values.terms}
                  onChange={handleChange}
                />
                <label for='check-24'>
                  <span></span> Prihvaćam{' '}
                  <Link to='/terms' className='text-[#4c617b] underline'>
                    uvjete korištenja
                  </Link>{' '}
                  i{' '}
                  <Link to='/privacy' className='text-[#4c617b] underline'>
                    pravila privatnosti
                  </Link>
                </label>
              </div>
            </div>
            {touched.terms && errors.terms && (
              <span className='text-red-700 text-sm p-0 m-0'>
                {errors.terms}
              </span>
            )}

            <div className='my-3'>
              <ReCAPTCHA
                sitekey={recaptchaKey} // Replace with your site key
                onChange={(value) =>
                  handleRecaptchaChange(value, setFieldValue)
                } // Pass setFieldValue here
              />
              {touched.recaptcha && errors.recaptcha && (
                <span className='text-red-700 text-sm p-0 m-0'>
                  {errors.recaptcha}
                </span>
              )}
            </div>
          </div>
          <FormAction text='Registriraj se' />
        </Form>
      )}
    </Formik>
  );
}
