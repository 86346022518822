import { Link } from 'react-router-dom';

export default function FormExtra() {
  return (
    <div
      className='flex items-center justify-center'
      style={{ marginTop: '1rem' }}
    >
      <div className='text-sm'>
        <Link
          to='/password-reset'
          className='font-medium text-[#4c617b] underline hover:text-[#000] '
        >
          Zaboravio si lozinku?
        </Link>
      </div>
    </div>
  );
}
