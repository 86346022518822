import React, { useContext } from 'react';
import { auth } from '../config/config';
import { useNavigate } from 'react-router-dom';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';

export const LogoutButton = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);

  const { clearEmployees } = GlobalContext;

  const { dispatch, user } = AuthContext;

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await auth.signOut();

      dispatch({ type: 'CLEAR_USER' });
      if (!user) {
        navigate('/');
      }
    } catch (error) {}
  };

  return (
    <button className='px-5 py-1.5' onClick={handleLogout}>
      Odjava
    </button>
  );
};
