import React from 'react';
import { Tooltip } from 'react-tooltip';

const TableView = ({
  paginatedDays,
  currentPage,
  daysPerPage,
  handleInputChange,
  handleBlur,
  calculateDayHours,
}) => {
  const tooltips = [
    { id: 'datum-tooltip', content: 'Datum rada' },
    { id: 'dolazak-tooltip', content: 'Vrijeme dolaska na posao' },
    { id: 'odlazak-tooltip', content: 'Vrijeme odlaska s posla' },
    { id: 'ukupno-tooltip', content: 'Ukupno radno vrijeme za taj dan' },
    { id: 'go-tooltip', content: 'Godišnji odmor' },
    { id: 'bolovanje-tooltip', content: 'Odsutnost zbog bolovanja' },
    { id: 'pla-tooltip', content: 'Plaćeni dopust' },
    { id: 'nep-tooltip', content: 'Neplaćeni dopust' },
    { id: 'sluz-tooltip', content: 'Službeni put' },
    { id: 'prek-tooltip', content: 'Prekovremeni rad' },
    { id: 'dp-tooltip', content: 'Državni praznik' },
  ];

  return (
    <table className='table-worklog mt-10'>
      <thead className='bg-gray-300 border border-1'>
        <tr className='bg-800-gray text-800-gray'>
          <th
            className='px-4 py-3 font-semibold'
            data-tooltip-id='datum-tooltip'
            data-tooltip-content='Datum rada'
          >
            Datum
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='dolazak-tooltip'
            data-tooltip-content='Vrijeme dolaska na posao'
          >
            Dolazak
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='odlazak-tooltip'
            data-tooltip-content='Vrijeme odlaska s posla'
          >
            Odlazak
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='ukupno-tooltip'
            data-tooltip-content='Ukupno radno vrijeme za taj dan'
          >
            Ukupno
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='go-tooltip'
            data-tooltip-content='Godišnji odmor'
          >
            Go
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='bolovanje-tooltip'
            data-tooltip-content='Odsutnost zbog bolovanja'
          >
            Bolovanje
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='pla-tooltip'
            data-tooltip-content='Plaćeni dopust'
          >
            Pla
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='nep-tooltip'
            data-tooltip-content='Neplaćeni dopust'
          >
            Nep
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='sluz-tooltip'
            data-tooltip-content='Službeni put'
          >
            Služ
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='prek-tooltip'
            data-tooltip-content='Prekovremeni rad'
          >
            Prek
          </th>
          <th
            className='px-4 py-2 font-semibold'
            data-tooltip-id='dp-tooltip'
            data-tooltip-content='Državni praznik'
          >
            DP
          </th>
        </tr>
      </thead>
      {/* Dodajte Tooltip komponentu za svaki `data-tooltip-id` */}
      <Tooltip id='datum-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='dolazak-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='odlazak-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='ukupno-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='go-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='bolovanje-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='pla-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='nep-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='sluz-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='prek-tooltip' place='top' type='dark' effect='solid' />
      <Tooltip id='dp-tooltip' place='top' type='dark' effect='solid' />

      <tbody className='bg-white'>
        {paginatedDays?.map((day, pageIndex) => {
          // Skip rendering if day is null
          if (!day) return null;

          const absoluteIndex = currentPage * daysPerPage + pageIndex; // Calculate the absolute index

          return (
            <tr key={absoluteIndex}>
              <td
                className={`border px-4 py-2 ${
                  day.dayOfWeek === 'Sub' || day.dayOfWeek === 'Ned'
                    ? 'bg-red-300'
                    : 'bg-blue-100'
                }`}
              >
                {day.dayOfWeek && new Date(day.date).getDate()}
                {'.' + day.dayOfWeek}
              </td>
              <td className='border px-4 py-2 relative'>
                <input
                  className='w-full'
                  name='startShift'
                  type='text'
                  value={day.startShift || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'startShift')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2 relative'>
                <input
                  className='w-full'
                  type='text'
                  name='endShift'
                  value={day.endShift || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'endShift')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>{calculateDayHours(day)}</td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='vacation'
                  value={day.vacation || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'vacation')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='sickLeave'
                  value={day.sickLeave || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'sickLeave')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='paidLeave'
                  value={day.paidLeave || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'paidLeave')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='unpaidLeave'
                  value={day.unpaidLeave || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'unpaidLeave')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='bussinesTravelHours'
                  value={day.bussinesTravelHours || ''}
                  onChange={(event) =>
                    handleInputChange(
                      event,
                      absoluteIndex,
                      'bussinesTravelHours'
                    )
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='overTimeHours'
                  value={day.overTimeHours || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'overTimeHours')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
              <td className='border px-4 py-2'>
                <input
                  className='w-full'
                  type='text'
                  name='nationalHoliday'
                  value={day.nationalHoliday || ''}
                  onChange={(event) =>
                    handleInputChange(event, absoluteIndex, 'nationalHoliday')
                  }
                  onBlur={(e) => handleBlur(e, day)}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableView;
