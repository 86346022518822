import React from 'react';

const ClockIcon = () => {
  return (
    <svg
      width='59'
      height='56'
      viewBox='0 0 59 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M29.4828 4.66667C26.2563 4.66667 23.0615 5.2702 20.0806 6.44281C17.0998 7.61542 14.3913 9.33414 12.1099 11.5008C7.5023 15.8767 4.9138 21.8116 4.9138 28C4.9138 34.1884 7.5023 40.1233 12.1099 44.4992C14.3913 46.6659 17.0998 48.3846 20.0806 49.5572C23.0615 50.7298 26.2563 51.3333 29.4828 51.3333C35.9989 51.3333 42.2481 48.875 46.8556 44.4992C51.4632 40.1233 54.0517 34.1884 54.0517 28C54.0517 24.9358 53.4162 21.9016 52.1815 19.0707C50.9468 16.2398 49.1371 13.6675 46.8556 11.5008C44.5742 9.33414 41.8657 7.61542 38.8849 6.44281C35.9041 5.2702 32.7092 4.66667 29.4828 4.66667ZM39.8017 37.8L27.0259 30.3333V16.3333H30.7112V28.4667L41.7672 34.7667L39.8017 37.8Z'
        fill='white'
      />
    </svg>
  );
};

export default ClockIcon;
