import { db } from '../config/config';
import moment from 'moment';
import {
  doc,
  collection,
  onSnapshot,
  addDoc,
  updateDoc,
  getDoc,
  deleteDoc,
  setDoc,
  increment,
  Timestamp,
} from 'firebase/firestore';

import { auth } from '../config/config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { calculateSubscriptionStatus } from '../helpers/helpers';

// Update employee

export const updateEmployee = async (
  employeeId,
  employeeData,
  currentUserUid,
  navigate,
  setLoading,
  setAlert
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    await updateDoc(employeeRef, employeeData);

    setAlert(
      'Zaposlenik je ažuriran',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    await navigate('/');
  } catch (e) {
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    setLoading(false);
  }
};

export const displayEmployees = (currentUserUid, dispatch) => {
  const userEmployeesRef = collection(db, 'users', currentUserUid, 'employees');

  const unsubscribe = onSnapshot(userEmployeesRef, (snapshot) => {
    const employeeList = [];

    snapshot.forEach((doc) => {
      const employeeData = { id: doc.id, ...doc.data() };
      // Destructure to exclude workingMonths
      const { workingMonths, ...filteredEmployee } = employeeData;
      /*  employeeList.push(employeeData); */
      employeeList.push(filteredEmployee);
    });

    dispatch({ type: 'DISPLAY_EMPLOYEES', payload: employeeList }); // Dispatch employee list
  });

  return unsubscribe; // Return unsubscribe function
};

export const getCompanyDetails = async (currentUserUid, dispatch) => {
  const userDocRef = doc(db, 'users', currentUserUid); // Reference to the user document

  try {
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const companyDetails = userDoc.data(); // Retrieve the company details

      // Recalculate the subscription status
      const recalculatedSubscriptionStatus = calculateSubscriptionStatus(
        companyDetails.subscriptionStatus
      );

      // Update the local state with recalculated details
      dispatch({
        type: 'GET_COMPANY_DETAILS',
        payload: {
          ...companyDetails,
          subscriptionStatus: recalculatedSubscriptionStatus,
        },
      });

      // Update the recalculated subscription status in Firebase
      await updateDoc(userDocRef, {
        subscriptionStatus: recalculatedSubscriptionStatus,
      });

      return {
        ...companyDetails,
        subscriptionStatus: recalculatedSubscriptionStatus,
      }; // Return the updated company details
    } else {
      console.log('No such user document!');
      return null; // Return null if no document found
    }
  } catch (error) {
    console.error('Error fetching company details:', error); // Error handling
    return null; // Return null on error
  }
};
//Register user

// Function to register a new user and add company details
export const registerUser = async (
  email,
  password,
  phone,
  companyName,
  pricingPlan,
  navigate,
  setAlert,
  dispatch,
  terms,
  sendEmailTemplate
) => {
  try {
    dispatch({ type: 'SET_SMALL_LOADING', payload: true });
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Set trial start and end dates
    const trialStart = Timestamp.now();
    const trialEnd = Timestamp.fromDate(
      new Date(Date.now() + 14 * 24 * 60 * 60 * 1000) // 14 days from now
    );

    // Create a user document in Firestore
    await setDoc(doc(db, 'users', user.uid), {
      companyName: companyName,
      pricingPlan: pricingPlan,
      email: email,
      phone: phone,
      termsAccepted: terms, // Da li su uvjeti prihvaćeni (boolean)
      termsAcceptedAt: Timestamp.now(), // Kada je korisnik prihvatio uvjete
      subscriptionStatus: {
        trialStart,
        trialEnd,
        isTrialActive: true,
        isPaidUser: false,
        paidUntil: null,
        subscriptionActive: false,
        remainingTrialDays: 14,
        message: 'Trial is active. 14 day(s) remaining.',
      },
    });

    setAlert(
      'Korisnik je uspješno registriran',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );

    const newDate = moment(trialEnd.toDate()).format('DD.MM.YYYY');

    await sendEmailTemplate(email, companyName, newDate);

    navigate('/');
    return user.uid;
  } catch (error) {
    if (error.code === 'auth/email-already-in-use') {
      setAlert(
        'Email već postoji',
        'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
      );
    } else if (error.code === 'auth/weak-password') {
      setAlert(
        'Password is too weak. Please choose a stronger password.',
        'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
      );
    } else {
      setAlert(
        'Došlo je do greške prilikom registracije. Molimo pokušajte ponovo.',
        'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
      );
    }
    console.error('Error registering user:', error.message);
  }
  dispatch({ type: 'SET_SMALL_LOADING', payload: false });
};

// add new employee

// Function to add an employee to the collection with reference collection(db, 'users', currentUserUid, 'employees')
export const addEmployee = async (
  employeeData,
  workingMonthsData,
  currentUserUid,
  navigate,
  setAlert
) => {
  try {
    // Reference to the user's document
    const userRef = doc(db, 'users', currentUserUid);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error('User does not exist');
    }

    // Get the user's pricing plan and current employee count
    const userData = userSnapshot.data();
    const { pricingPlan, employeeCount = 0 } = userData;

    // Define employee limits based on pricing plan
    const planLimits = {
      6: 6,
      10: 10,
      12: 12,
      25: 25,
      40: 40,
    };
    const maxEmployees = planLimits[pricingPlan] || 6; // Default to 5 if pricingPlan is undefined

    if (employeeCount === maxEmployees) {
      setAlert(
        'Dostigli ste maksimalan broj zaposlenika za vaš plan.',
        'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
      );
      return null;
    } else {
      // Proceed to add employee if under the limit
      const employeeRef = collection(db, 'users', currentUserUid, 'employees');
      const docRef = await addDoc(employeeRef, employeeData);
      const id = docRef.id;

      // Add working months data
      addWorkingMonths(workingMonthsData, currentUserUid, id, navigate);

      // Update the employee count in the user document
      await setDoc(userRef, { employeeCount: increment(1) }, { merge: true });

      setAlert(
        'Zaposlenik je uspješno dodan',
        'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
      );

      navigate('/dashboard');

      return docRef.id;
    }
  } catch (e) {
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
    return null;
  }
};

// Function to add working months to an employee with a specific ID in the collection with reference collection(db, 'users', currentUserUid, 'employees')
export const addWorkingMonths = async (
  workingMonthsData,
  currentUserUid,
  employeeId,
  navigate
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    await updateDoc(employeeRef, {
      workingMonths: workingMonthsData,
    });
  } catch (e) {
    return null;
  }
};
// Delete eymployee

export const deleteEmployee = async (
  currentUserUid,
  employeeId,
  setAlert,
  dispatch
) => {
  const employeeRef = doc(db, 'users', currentUserUid, 'employees', employeeId);
  const userRef = doc(db, 'users', currentUserUid);

  try {
    // Delete the employee document
    await deleteDoc(employeeRef);
    console.log('Employee deleted successfully!');

    // Get current employee count
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const currentCount = userDoc.data().employeeCount || 0;

      // Decrement the count and update it in Firestore
      await updateDoc(userRef, {
        employeeCount: Math.max(currentCount - 1, 0), // Ensure count doesn't go below 0
      });
    }

    setAlert(
      'Zaposlenik je izbrisan',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
    getCompanyDetails(currentUserUid, dispatch);
  } catch (error) {
    console.error('Error deleting employee or updating count:', error);
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
  }
};

export const getEmployee = async (currentUserUid, employeeId, dispatch) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    const employeeDoc = await getDoc(employeeRef);

    if (employeeDoc.exists()) {
      return { id: employeeDoc.id, ...employeeDoc.data() };
    } else {
      console.log('No such employee document!');
      return null;
    }
  } catch (e) {
    console.error('Error getting employee document: ', e);
    return null;
  }
};

// display employee by month

export const getMonthByEmployee = async (
  selectedMonth,
  currentUserUid,
  employeeId,
  newMonthData,
  setAlert
) => {
  const employeeRef = doc(db, 'users', currentUserUid, 'employees', employeeId);
  const employeeDoc = await getDoc(employeeRef);

  if (!employeeDoc.exists()) {
    console.log(`Employee with ID '${employeeId}' does not exist`);
    setAlert('Zaposlenik ne postoji');
    return null;
  }

  const employeeData = employeeDoc.data();

  if (!employeeData) {
    console.log(`Employee with ID '${employeeId}' does not have any data`);
    return null;
  }
  const workingMonths = employeeData.workingMonths;

  // If no selectedMonth is provided, get the last month in the workingMonths array
  if (!selectedMonth) {
    const lastMonth = workingMonths[workingMonths.length - 1];
    selectedMonth = lastMonth.month;
  }

  const monthData = workingMonths.find(
    (month) => month.month === selectedMonth
  );
  // Ovdje moze se staviti createMonth jer kad nema mjeseca onda tako moze
  if (!monthData) {
    console.log(
      `Employee with ID '${employeeId}' does not have any data for month '${selectedMonth}'`
    );

    // Call addNewMonthByEmployee and return the result
    return addNewMonthByEmployee(currentUserUid, employeeId, newMonthData).then(
      (result) => {
        // Call getMonthByEmployee again after the inner API call is finished
        return getMonthByEmployee(selectedMonth, currentUserUid, employeeId);
      }
    );
  }

  return {
    employeeId,
    firstName: employeeData.firstName,
    lastName: employeeData.lastName,
    month: monthData.month,
    workingMonths: monthData,
    startDate: employeeData.startDate,
    endDate: employeeData.endDate,
    weekShift: employeeData.weekShift,
  };
};

export const getEmployeeMonths = async (employeeId, currentUserUid) => {
  const employeeRef = doc(db, 'users', currentUserUid, 'employees', employeeId);
  const employeeDoc = await getDoc(employeeRef);
  if (!employeeDoc.exists()) {
    console.log('No such employee document!');
    return [];
  }
  const employeeData = employeeDoc.data();
  const workingMonths = employeeData.workingMonths || [];
  const months = workingMonths.map((workingMonth) => workingMonth.month);
  return months;
};

export const updateMonthByEmployee = async (
  currentUserUid,
  employeeId,
  selectedMonth,
  updatedData,
  setAlert
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return null;
    }

    const employeeData = employeeDoc.data();
    const workingMonths = employeeData.workingMonths || [];

    // Find the index of the month to update
    const monthIndex = workingMonths.findIndex(
      (month) => month.month === selectedMonth
    );

    if (monthIndex === -1) {
      console.log(
        `Mjesec '${selectedMonth}' nije pronađen za zaposlenika '${employeeId}'`
      );

      return null;
    }

    // Update the data for the selected month
    workingMonths[monthIndex] = {
      workingDays: [...updatedData],
      month: workingMonths[monthIndex].month,
    };

    // Update the employee document with the modified workingMonths array
    await updateDoc(employeeRef, { workingMonths });

    setAlert(
      'Mjesec je ažuriran',
      'bg-green-100 font-semibold dark:bg-gray-900 dark:text-red-400 font-normal'
    );

    return {
      employeeId,
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      workingMonths: workingMonths[monthIndex],
      startDate: employeeData.startDate,
      weekShift: employeeData.weekShift,
    };
  } catch (e) {
    console.error('Error updating employee document: ', e);
    setAlert(
      'Greška na serveru',
      'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold'
    );
    return null;
  }
};

export const addNewMonthByEmployee = async (
  currentUserUid,
  employeeId,
  newMonthData
) => {
  try {
    const employeeRef = doc(
      db,
      'users',
      currentUserUid,
      'employees',
      employeeId
    );
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return null;
    }

    const employeeData = employeeDoc.data();
    const workingMonths = employeeData.workingMonths || [];

    // Check if the month already exists
    const existingMonthIndex = workingMonths.findIndex(
      (month) => month.month === newMonthData.month
    );

    if (existingMonthIndex !== -1) {
      console.log(
        `Month '${newMonthData.month}' already exists for employee '${employeeId}'`
      );
      return null;
    }

    // Add the new month data to the workingMonths array
    workingMonths.push(newMonthData);

    // Update the employee document with the modified workingMonths array
    await updateDoc(employeeRef, { workingMonths });

    return {
      employeeId,
      firstName: employeeData.firstName,
      lastName: employeeData.lastName,
      newMonthData,
      startDate: employeeData.startDate,
      weekShift: employeeData.weekShift,
    };
  } catch (e) {
    console.error('Error adding new month for employee: ', e);
    return null;
  }
};

// Assume updatedDay is the day being updated

// Your onBlur handler
export const updatesDayInFirestore = async (
  userId,
  employeeId,
  selectedMonth, // Use a different variable name to avoid confusion
  updatedDay
) => {
  try {
    const employeeRef = doc(db, 'users', userId, 'employees', employeeId);
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return;
    }

    const employeeData = employeeDoc.data();
    const workingMonths = employeeData.workingMonths || [];

    console.log(workingMonths, selectedMonth);

    // Find the index of the month to update
    const monthIndex = workingMonths.findIndex(
      (month) => month.month === selectedMonth
    );

    if (monthIndex === -1) {
      console.log(
        `Month '${selectedMonth}' not found for employee '${employeeId}'`
      );
      return;
    }

    // Update the specific day in the workingDays array
    const updatedWorkingDays = workingMonths[monthIndex].workingDays.map(
      (day) => (day.date === updatedDay.date ? updatedDay : day)
    );

    // Construct the modified workingMonths array
    const updatedWorkingMonths = [
      ...workingMonths.slice(0, monthIndex),
      {
        month: workingMonths[monthIndex].month,
        workingDays: updatedWorkingDays,
      },
      ...workingMonths.slice(monthIndex + 1),
    ];

    // Update the employee document with the modified workingMonths array
    await updateDoc(employeeRef, { workingMonths: updatedWorkingMonths });

    console.log('Document updated successfully');
  } catch (error) {
    console.error('Error updating employee document: ', error);
  }
};

export const updateDayInFirestore = async (
  userId,
  employeeId,
  month,
  updatedDay
) => {
  try {
    const employeeRef = doc(db, 'users', userId, 'employees', employeeId);
    const employeeDoc = await getDoc(employeeRef);

    if (!employeeDoc.exists()) {
      console.log(`Employee with ID '${employeeId}' does not exist`);
      return;
    }

    const data = employeeDoc.data();

    // Find the index of the month to be updated in the workingMonths array
    const monthIndex = data.workingMonths.findIndex(
      (entry) => entry.month === month
    );

    if (monthIndex !== -1) {
      // Update only the specific day in the workingDays array
      data.workingMonths[monthIndex].workingDays = data.workingMonths[
        monthIndex
      ].workingDays.map((day) => {
        if (day.date === updatedDay.date) {
          // Update only the fields that have changed
          return {
            ...day,
            ...updatedDay,
          };
        }
        return day;
      });

      // Update the document in Firestore with the modified data

      await updateDoc(employeeRef, data);
    } else {
      // Handle the case where the month is not found
      console.error('Month not found');
    }
  } catch (error) {
    console.error('Error updating day in Firestore:', error);
  }
};
