import { useContext, useState } from 'react';
import { loginFields } from '../constants/formFields';
import FormAction from './FormAction';
import FormExtra from './FormExtra';
import Input from './Input';
import authContext from '../contexts/auth/authContext';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

const fields = loginFields;

export default function Login() {
  const AuthContext = useContext(authContext);
  const { loginUser } = AuthContext;
  const [rememberMe, setRememberMe] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Unesite validan format e-maila')
      .required('Email je obavezan'),
    password: Yup.string()
      .min(6, 'Lozinka mora imati najmanje 6 karaktera')
      .required('Lozinka je obavezna'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        loginUser({ ...values, rememberMe });
      }}
    >
      {({ handleChange, handleSubmit, values, errors, touched }) => (
        <Form onSubmit={handleSubmit} className='space-y-6'>
          <div className='py-1'>
            {fields.map((field) => (
              <div key={field.id}>
                <Input
                  handleChange={handleChange}
                  value={values[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.id}
                  type={field.type}
                  isRequired={field.isRequired}
                />
                {touched[field.id] && errors[field.id] && (
                  <span className='text-red-700 text-sm p-0 m-0'>
                    {errors[field.id]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <FormAction text='Prijavi se' />
          <FormExtra rememberMe={rememberMe} setRememberMe={setRememberMe} />
        </Form>
      )}
    </Formik>
  );
}
