import React, { useContext, useEffect } from 'react';
import Login from '../components/Login';
import { auth } from '../config/config';
import authContext from '../contexts/auth/authContext';
import { useNavigate } from 'react-router-dom';
import globalContext from '../contexts/satnica/globalContext';
import Loader from '../components/Loader';
import { Helmet } from 'react-helmet';

const LoginPage = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);

  const { clearEmployees } = GlobalContext;

  const { loading, user, dispatch, smallLoading } = AuthContext;

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && !loading) {
        dispatch({ type: 'SET_USER', payload: user });
        navigate('/dashboard');
      } else {
        dispatch({ type: 'CLEAR_USER' });

        clearEmployees();
      }
    });

    return unsubscribe;
    //eslint-disable-next-line
  }, [user, auth]);

  if (!user && loading) {
    return <Loader />;
  } else
    return (
      <div className=''>
        <Helmet>
          <title>Satnica - Aplikacija za praćenje radnog vremena</title>
          <meta
            name='description'
            content='Prijavite se na Satnica aplikaciju za praćenje radnog vremena.'
          />
          <meta
            name='keywords'
            content='login, aplikacija, praćenje radnog vremena, preduzeća'
          />

          <meta
            name='og:description'
            content='Prijavite se na Satnica aplikaciju za praćenje radnog vremena.'
          />
          <meta property='og:title' content='Login – Satnica Aplikacija' />
          <meta property='og:image' content='/login-picture.png' />
          <meta property='og:image:width' content='1143' />
          <meta property='og:image:height' content='580' />
          <meta property='og:url' content='https://app.satnica.net/' />
          <meta property='og:type' content='website' />
        </Helmet>
        <div className='flex mx-auto flex-col py-12 px-4 sm:px-6 lg:px-8 md:w-[30rem]'>
          <div className='mb-10 text-center'>
            <h2
              className='mt-6 text-center text-3xl text-gray-900 font-sans font-bold'
              style={{ fontFamily: 'Roboto' }}
            >
              Prijavi se
            </h2>
            <p className='mt-4'>Upišite vaše korisničko ime i lozinku</p>
            <div>{smallLoading && <Loader />}</div>
          </div>
          <Login />
        </div>
      </div>
    );
};

export default LoginPage;
