import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import Loader from '../components/Loader';

const PrivateRoutes = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);
  const { user } = AuthContext;
  const { companyDetails, loading } = GlobalContext; // assuming loader is part of globalContext
  const { companyName } = companyDetails;
  // Show loader until authentication and global data are ready
  if (loading && !companyName && !user) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // If authenticated and companyName is available, load protected content
  return user ? (
    <div className='pb-7 pt-6' style={{ minHeight: '600px' }}>
      {/*  <Menu /> */}
      <div className='mx-auto container custom-container'>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoutes;
